/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {KTIcon} from '../../../helpers'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import { useAuth } from '../../../../modules/auth'

export function AsideMenuMain() {
  const intl = useIntl()
  const {currentUser} = useAuth()
  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='element-11'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
      />
      {currentUser?.username==='admin'&&

      <>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Master</span>
        </div>
      </div>
      <AsideMenuItem to='/master/event' icon='calendar' title='Event' />
      <AsideMenuItem to='/master/participant' icon='profile-circle' title='Participant' />
      <AsideMenuItem to='/master/check-point' icon='shield-tick' title='Check Point' />
      <AsideMenuItem to='/master/assign' icon='entrance-left' title='Assign Participant' />
      <AsideMenuItem to='/master/user' icon='account' title='User List' />
      </>
    }
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Report</span>
        </div>
      </div>
      <AsideMenuItem to='/report' icon='cup' title='Report' />
    </>
  )
}
