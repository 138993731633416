import React from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../_metronic/layout/core'
import {
  ListsWidget1,
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  ListsWidget5,
  ListsWidget6,
  MixedWidget10,
  MixedWidget11,
  MixedWidget2,
  StatisticsWidget5,
  TablesWidget10,
  TablesWidget5,
} from '../../_metronic/partials/widgets'
import ReactApexChart from 'react-apexcharts'
import { HelperEvent } from '../../modules/master/core/helper/helperRequest'
import i18n from '../../i18n'
import Select2 from '../../components/Form/Select2'
import { ReportChard } from '../../modules/report/core/report/_requests'
import { formatTime } from '../../components/formatTime'

class DashboardWrapper extends React.Component<{[key: string]: any}, {[key: string]: any}> {
  constructor(props: any) {
    super(props)

    this.state = {
      eventList:[],
      eventText:i18n.t('Select Event'),
      eventValue:null,
      showChart:false,
      isProcess:false,
      series: [],
      options: {
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
        },
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              total: {
                enabled: true,
                offsetX: 0,
                style: {
                  fontSize: '13px',
                  fontWeight: 900,
                },
              },
            },
          },
        },
        stroke: {
          width: 1,
          colors: ['#fff'],
        },
        title: {
          text: '',
        },
        xaxis: {
          categories: [],
          labels: {
            formatter: function (val: any) {
              return val + 'Sec'
            },
          },
        },
        yaxis: {
          title: {
            text: undefined,
          },
        },
        tooltip: {
          y: {
            formatter: function (val: any) {
              return formatTime(val)
            },
          },
        },
        fill: {
          opacity: 1,
        },
        legend: {
          position: 'top',
          horizontalAlign: 'left',
          offsetX: 40,
        },
      },
      totalHeight:350
    }
  }
  componentDidMount(): void {
    HelperEvent().then((response)=>{
      this.setState({eventList:response.data.result})
    })
  }
  loadChart(){
      if(this.state.eventValue != null){
        this.setState({isProcess:true})
        ReportChard(this.state.eventValue.value).then((response)=>{
          let totalHeight=this.state.totalHeight
          if(response.data.result.categories.length >10){
            totalHeight=30 * response.data.result.categories.length
          }
          this.setState({
            ...this.state,
            totalHeight:totalHeight,
            series:response.data.result.series,
            options:{
              ...this.state.options,
              chart:{
                ...this.state.options.chart,
                height:totalHeight
              },
              xaxis:{
                ...this.state.options.xaxis,
                categories:response.data.result.categories
              }
            }

          })
        }).catch((error)=>{

        })
      }
     
  }

  render() {
    return (
      <div id='chart'>
          <div className="card">
            <div className="card-body">
              <div className='row'>
            <div className='col-md-3'>
            <Select2 
                name={'eventID'}
                required={true}
                onChange={async(e:any)=>{
                      if(e==null){
                        this.setState({eventValue:null,showChart:false})
                      }else{
                        if(e != this.state.eventValue){
                          this.setState({...this.state,
                            eventValue:e,
                            showChart:true,
                            options:{
                              ...this.state.options,
                              title:{
                                text:'Event : '+ e.label,
                                style: {
                                  fontSize:  '18px',
                                  fontWeight:  '900',
                                  fontFamily:  undefined,
                                  color:  '#263238'
                                },
                              }
                            }
                          },()=>{
                            this.loadChart()
                          })
                        }
                      }
                    }
                  }
                isClearable
                options={this.state.eventList}
                title={i18n.t("Event")}
                value={this.state.eventValue}
                placeholder={this.state.eventText}
            />
            </div>
            </div>
            {this.state.showChart && <ReactApexChart
                options={this.state.options}
                series={this.state.series}
                type='bar'
                height={this.state.totalHeight}
              />}
              
        </div>
        </div>
      </div>
    )
  }
}

export {DashboardWrapper}
