import axios from 'axios'
import {ReportModel} from './_models'
import { getAuth } from '../../../auth'

const API_URL = process.env.REACT_APP_API_URL

export const CURRENT_URL = `${API_URL}/transaction/log-check-point`

// Server should return AuthModel
export function ReportList(search:any) {
  return axios.get<ReportModel>(CURRENT_URL, {
    params:search
  })
}

export function ReportChard(id:any){
  return axios.get(CURRENT_URL+'/chart/'+id)
}


export function setupAxios(axios: any) {
  axios.defaults.headers.Accept = 'application/json'
  axios.interceptors.request.use(
    (config: {headers: {Authorization: string}}) => {
      const auth = getAuth()
      if (auth && auth.accessToken) {
        config.headers.Authorization = `Bearer ${auth.accessToken}`
      }
      return config
    },
    (err: any) => Promise.reject(err)
  )
}

