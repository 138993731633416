import axios from 'axios'
// import {EventModel} from './_models'
import { getAuth } from '../../../auth'

const API_URL = process.env.REACT_APP_API_URL

export const GET_LIST = `${API_URL}/master/event`

export function HelperProvince() {
  return axios.get(`${API_URL}/helper/helper-province`)
}

// Server should return AuthModel
export function HelperCity(params:any) {
  return axios.get(`${API_URL}/helper/helper-city`, { params: params })
}

export function HelperDistrict(params:any) {
  return axios.get(`${API_URL}/helper/helper-district`, { params: params })
}

export function HelperSubDistrict(params:any) {
  return axios.get(`${API_URL}/helper/helper-sub-district`, { params: params })
}

export function HelperEvent(params?:any){
  return axios.get(`${API_URL}/helper/helper-event`, { params: params })
}

export function HelperParticipant(params:any){
  return axios.get(`${API_URL}/helper/helper-participant`, { params: params })
}

export function HelperCheckPoint(params:any){
  return axios.get(`${API_URL}/helper/helper-check-point`, { params: params })
}

export function setupAxios(axios: any) {
  axios.defaults.headers.Accept = 'application/json'
  axios.interceptors.request.use(
    (config: {headers: {Authorization: string}}) => {
      const auth = getAuth()
      if (auth && auth.accessToken) {
        config.headers.Authorization = `Bearer ${auth.accessToken}`
      }
      return config
    },
    (err: any) => Promise.reject(err)
  )
}

